import i18n from 'i18next';
// https://github.com/i18next/i18next-xhr-backend
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import queryString from 'query-string';

i18n
  .use(Backend)
  .use(initReactI18next)

const i18nInit = i18n.init({
  fallbackLng: 'en',
  lng: queryString.parse(window.location.search).lang,
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'em', 'a'],
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json'
  },
})

export default i18nInit;