import './css/annotations.css';
import Spinner from './components/Spinner';
import React, { useState } from 'react';
import AnnotationForm from './AnnotationForm';
import { Draggable } from 'react-beautiful-dnd';
import OpenSeadragon from 'openseadragon';
import { useTranslation } from 'react-i18next';

function AnnotationItem(props) {
  const [showEditAnnotation, setShowEditAnnotation] = useState(false);
  const [checkingDeleteAnnotation, setCheckingDeleteAnnotation] = useState(
    false
  );
  const [t] = useTranslation();

  const truncate = string => {
    return string.length > 80
      ? string
          .slice(0, 80)
          .trimRight()
          .concat('...')
      : string;
  };

  const toggleEditForm = () => {
    props.setEditingItem(!showEditAnnotation ? props.itemKey : null);
    setShowEditAnnotation(!showEditAnnotation);
  };

  const deleteAnnotation = itemKey => {
    let deleted = null;
    let newAnnotations = props.annotations;
    setCheckingDeleteAnnotation(true);
    // Whenever an item is removed we need a new list of annotation
    // This must have the correct ordinal on the front end
    // N.B. The back end will not sync the ordinal values themselves with this op
    newAnnotations = newAnnotations.filter((item, index) => {
      deleted = item.id === itemKey ? item : deleted;
      item.ordinal = deleted ? index - 1 : index;
      return item.id !== itemKey;
    });

    fetch(`${props.annotationEndpoint}/delete/${deleted.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        props.setAnnotations(newAnnotations);
        setCheckingDeleteAnnotation(false);
      })
      .catch(err => {
        console.log('error');
        setCheckingDeleteAnnotation(false);
      });
  };

  const renderEditForm = () => {
    if (showEditAnnotation && props.editingItem === props.itemKey) {
      const itemData = props.annotations[props.ordinal];
      const { x, y, width, height } = itemData.coords;

      let bounds = new OpenSeadragon.Rect(x, y, width, height);

      // The item should look at the region it edits
      props.osdInstance.viewport.fitBounds(
        props.osdInstance.viewport.imageToViewportRectangle(bounds)
      );

      props.setEditingItem(props.itemKey);
      console.log(props);
      return (
        <AnnotationForm
          invocation='edit'
          osdInstance={props.osdInstance}
          annotationEndpoint={props.annotationEndpoint}
          infoData={props.infoData}
          manifestId={props.manifestId}
          ordinal={props.ordinal}
          setShowEditAnnotation={setShowEditAnnotation}
          annotationId={props.data.id}
          annotations={props.annotations}
          setAnnotations={props.setAnnotations}
          itemKey={props.itemKey}
          storyID={props.data.storyID}
        />
      );
    }
  };

  const renderDeleteButton = () => {
    let text = t('AnnotationItem.delete');
    if (checkingDeleteAnnotation) {
      text = t('AnnotationItem.deleting');
    }
    return (
      <button
        className='btn btn-danger'
        onClick={() => deleteAnnotation(props.itemKey)}
        disabled={checkingDeleteAnnotation}
        id="delete-annotation"
      >
        {text}
        {checkingDeleteAnnotation && <Spinner/>}
      </button>
    );
  };

  const renderStatic = () => {
    if (!showEditAnnotation || props.editingItem !== props.itemKey) {
      return (
        <div>
          <span className='annotation__text'>{truncate(props.data.text)}</span>
          <div className='annotation__actions'>
            <button
              className='btn btn-primary'
              onClick={toggleEditForm}
              disabled={checkingDeleteAnnotation}
              id="edit-annotation"
            >
              {t('AnnotationItem.edit')}
            </button>
            {renderDeleteButton()}
          </div>
        </div>
      );
    }
  };

  return (
    <Draggable draggableId={props.itemKey} index={props.ordinal}>
      {provided => {
        return (
          <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {renderEditForm()}
            {renderStatic()}
          </li>
        );
      }}
    </Draggable>
  );
}

export default AnnotationItem;
