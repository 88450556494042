import './css/annotations.css';
import Spinner from './components/Spinner';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function AnnotationForm(props) {
  const [annotation, setAnnotation] = useState('');
  const [checkingAnnotation, setCheckingAnnotation] = useState(false);
  const [t] = useTranslation();
  const annotationField = useRef(null);
  let bounds;
  let initText;
  let initBounds;

  const handleSubmission = () => {
    let setShowAlias;
    bounds = props.osdInstance.viewport.viewportToImageRectangle(
      props.osdInstance.viewport.getBounds()
    );

    // This is the payload for fetch
    // will also be added/updated in a cloned state array
    const annotationData = {
      storyID: props.storyID,
      imageURL: props.infoData.url.split('/info.json')[0],
      text: annotation,
      coords: { ...bounds },
      ordinal: props.annotations.length
    };

    // The differences between add / edit are
    // what shouldn't trigger a fetch
    // and the method to toggle the form

    if (props.invocation === 'add') {
      setShowAlias = props.setShowAddAnnotation;

      // GTM: Push new annotation to dataLayer (if not empty)
      if (annotation) {
        window.dataLayer.push({
          'event': 'annotationAdded',
          'annotation': annotation,
          'annotation-ordinal': props.annotations.length
        });
      }

      // Don't process empty submissions
      if (!annotation) return;
    } else {
      annotationData.id = props.itemKey;
      annotationData.ordinal = props.ordinal;
      setShowAlias = props.setShowEditAnnotation;

      // Don't process empty / unchanged submissions
      if (
        !annotation ||
        (initText === props.annotation && initBounds === bounds)
      )
        return;
    }
    setCheckingAnnotation(true);
    axios
      .post(props.annotationEndpoint, JSON.stringify(annotationData))
      .then(res => {
        annotationData.id = res.data.id;
        setCheckingAnnotation(false);
        addOrUpdateAnnotations(annotationData);
        setShowAlias();         
      })
      .catch(err => {
        console.log(err);
        // TODO: Show error
        setCheckingAnnotation(false);
      });
  };

  const handleChange = e => {
    // For edit, copy find and update the correct annotation
    // Copy and replace with new array
    if (props.annotationId) {
      const annotations = props.annotations.slice();
      const item = annotations.find(match => match.id === props.annotationId);
      item.text = e.target.value;
    }
    // For the field value
    setAnnotation(e.target.value);
  };

  // Either add a whole new item to the annotations
  // or update existing properties
  const addOrUpdateAnnotations = annotationData => {
    let newAnnotations;
    if (props.invocation === 'add') {
      newAnnotations = props.annotations.concat(annotationData);
    } else {
      let { text, coords } = annotationData;
      newAnnotations = props.annotations.map(item => {
        if (item.id === annotationData.id) {
          item.text = text;
          item.coords = coords;
        }
        return item;
      });
    }
    props.setAnnotations(newAnnotations);
  };

  const buttonText = props.invocation === 'add' ? t('AnnotationForm.submit') : t('AnnotationForm.update');

  const renderSubmitButton = () => {
    let text = buttonText;
    if (checkingAnnotation) {
      text = t('AnnotationForm.saving');
    }

    return (
      <button
        className='btn btn-primary'
        onClick={handleSubmission}
        disabled={checkingAnnotation}
        id="add-annotation-btn"
      >
        {text}
        {checkingAnnotation && <Spinner/>}
      </button>
    );
  };

  useEffect(() => {
    // Set the default value on render for editing
    if (props.annotationId) {
      const annotation = props.annotations.find(
        match => match.id === props.annotationId
      );
      // Cache the initial value to check change
      initText = annotation;
      initBounds = props.osdInstance.viewport.viewportToImageRectangle(
        props.osdInstance.viewport.getBounds()
      );
      setAnnotation(annotation.text);
    }
    annotationField.current.focus();
  }, []);

  return (
    <div className='add-annotation card'>
      <div className='card-body'>
        <div className='form-group form-row'>
          <textarea
            className='form-control add-annoation__body'
            value={annotation}
            onChange={handleChange}
            ref={annotationField}
          ></textarea>
        </div>
        <div className='form-group form-row mb-0 justify-content-end'>
          {renderSubmitButton()}
        </div>
      </div>
    </div>
  );
}

export default AnnotationForm;
