import './css/annotations.css';
import React, { useState } from 'react';
import AnnotationItem from './AnnotationItem';
import { Droppable } from 'react-beautiful-dnd';

function AnnotationList(props) {
  const [editingItem, setEditingItem] = useState(null);

  const renderItems = () => {
    return (
      props.annotations.map((value, index) => {
        return (
          <AnnotationItem
            key={value.id}
            itemKey={value.id}
            data={value}
            ordinal={value.ordinal}
            osdInstance={props.osdInstance}
            annotations={props.annotations}
            setAnnotations={props.setAnnotations}
            editingItem={editingItem}
            setEditingItem={setEditingItem}
            annotationEndpoint={props.annotationEndpoint}
            manifestId={props.manifestId}
            infoData={props.infoData}
          />
        );
      })
    );
  };

  return (
    <Droppable droppableId="annotation-list">
      {(provided) => {
        return (
          <ul
            ref={provided.innerRef}
            className="annotations-list"
            {...provided.droppableProps}
          >
            {renderItems()}
            {provided.placeholder}
          </ul>
        );
      }}
    </Droppable>
  );
}

export default AnnotationList;