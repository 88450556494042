import React from 'react';
import Form from './Form';
import { Trans } from 'react-i18next';
import DangerousTranslation from './DangerousTranslation';


function ProviderLogo(props) {
  const providers = Object.keys(props.providerLogos);
  const { provider } = props.inputValues;
  if (providers.indexOf(provider) !== -1) {
    return (
      <a className="provider-logo" href={props.providerLogos[provider].href}>
        <img src={props.providerLogos[provider].src} alt={props.providerLogos[provider].alt} />
      </a>
    );
  }
  return null;
}

function Intro(props) {
  const renderProviderLogo = () => {
    return (
      <ProviderLogo
        inputValues={props.inputValues}
        providerLogos={props.providerLogos}
      />
    )
  };

  return (
    <div id="intro">
      <div className="intro-wrapper card">
        <div className="card-body">
          {renderProviderLogo()}
          <h1 className="card__header">
            <Trans i18nKey="Intro.heading">
              <strong>Storiiies</strong> Editor - Alpha Version 0.1
            </Trans>
          </h1>
          {/* Error message for issues re: retrieving manifest for already-created stories */}
          {props.errorMessage && (<div className="form-error-container mb-4" role="alert">{props.errorMessage}</div>)}
          <DangerousTranslation i18nKey="Intro.body" />
          <Form
            infoData={props.infoData}
            setInfoData={props.setInfoData}
            inputValues={props.inputValues}
            setInputValues={props.setInputValues}
            urlParams={props.urlParams}
            retrievingManifest={props.retrievingManifest}
          />
          <DangerousTranslation i18nKey="Intro.termsAndConditions" />
        </div>
        <div className="card-footer">
            <div className="image-credit footer-align">
                <DangerousTranslation i18nKey="Intro.credit" />
            </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
