import React from 'react';
import { useTranslation } from 'react-i18next';

function ViewerControls() {
  const [t] = useTranslation();
  const osdControlItems = [
    {
      id: 'zoom-in-button',
      label: t('ViewerControls.zoom in'),
      icon: '#zoom-in',
    },
    {
      id: 'zoom-out-button',
      label: t('ViewerControls.zoom out'),
      icon: '#zoom-out',
    },
  ];
  
  const renderControls = (controlItems) => {
    return controlItems.map((value,index) => {
      return(
        <button
          key={value.id}
          id={value.id}
          aria-label={value.label}
          className="btn btn-secondary osd-control"
        >
          <svg className="icon">
            <use xlinkHref={value.icon}></use>
          </svg>
        </button>
      );
    });
  };

  return (
    <div
      className="osd-controls btn-group d-flex flex-column align-items-center"
      role="group"
      aria-label={t('ViewerControls.image viewer controls')}
    >
      {renderControls(osdControlItems)}
    </div>      
  );
}

export default ViewerControls;
