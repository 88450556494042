import React from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
// This is of course not recommended
// but it will basically allow free text/html ion locale files
// Uses DOMPurify to mitigate XSS risks
function DangerousTranslation(props) {
  const [t] = useTranslation();
  const { i18nKey } = props;

  const createDangerousTransMarkup = (i18nKey) => {
    const html = t(i18nKey);
    const cleanHtml = DOMPurify.sanitize(html);
    return {__html: cleanHtml};
  }
  return (<div dangerouslySetInnerHTML={createDangerousTransMarkup(i18nKey)} />);
}

export default DangerousTranslation;