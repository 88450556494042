import 'bootstrap/dist/css/bootstrap.css';
import './css/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import i18nInit from './i18n';


i18nInit.then(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});
