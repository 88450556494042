import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import DangerousTranslation from '../DangerousTranslation';

export default function Links(props) {
  // overlay with links??
  const [show, setShow] = useState();

  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);
  const { storyID, viewURL, title, language } = props;
  const [t] = useTranslation();
 
  const generatePrivateLink = () => {
    const lang = language ? `&lang=${language}` : '';
    return (<a href={`https://storiiies-editor.cogapp.com/?sid=${storyID}${lang}`}>
    https://storiiies-editor.cogapp.com/?sid={storyID}{lang}
  </a>)
  }

  const embedViewURL = viewURL.replace('view', 'embed');

  return (
    <>
      <button onClick={handleOpen} className='btn btn-primary ml-2'>
        {t('StoriiiInfo.links')}
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Links.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('Links.bookmark to share')}</p>
          <p><a href={viewURL}>{viewURL}</a></p>
          <p>{t('Links.bookmark to edit')}</p>
          <p>
            {generatePrivateLink()}
          </p>
          <p>{t('Links.embed')}</p>
          <p>
            <textarea readOnly style={{width: '100%'}} defaultValue={`<iframe width="100%" height="600" src="${embedViewURL}" title="${title}"></iframe>`}>
            </textarea>
          </p>
          <hr />
          <DangerousTranslation i18nKey="Links.additional" />
          <p>
            <Trans i18nKey="Links.by cogapp">
              Production and software by <a href="https://www.cogapp.com">Cogapp</a>
            </Trans>
          </p>

          <DangerousTranslation i18nKey="Links.iiif info" />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className='btn btn-primary ml-2'>
            {t('Links.close')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
