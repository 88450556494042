// This mixes React and the native API's for validation
// Note that:
// - When inputs update, the state is updated and form validation is reset
// - Info.json URL has some custom validation rules
// - State is set on change
// - Validation checks are only ran on submission

import Spinner from './components/Spinner';
import FileUploadForm from './FileUploadForm';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Manifest } from "manifesto.js";

/**
 * Attempts to parse the `@context` field and return the IIIF Presentation API version number
 *
 * @param {Manifest} manifest A valid Manifest
 * @returns {"2" | "3"} The IIIF manifest's version.
 * @throws If not a v2 or v2 IIIF manifest
 */
function getManifestVersion(manifest) {
  const regex = /\/presentation\/(?<version>\d)\//;
  const match = manifest.context.match(regex);
  if (!match?.groups?.version) {
    throw new Error("Could not get manifest version");
  }
  return match.groups.version;
}

/**
 * Attempts to get the first resource of a manifest
 *
 * @param {Manifest} manifest A valid Manifest
 * @returns {{url: string, width: number, height: number}} The first resource's URL, width and height.
 * @throws If not a v2 or v2 IIIF manifest
 */
function getFirstResource(manifest) {
  const version = getManifestVersion(manifest);
  const canvas = manifest.getSequenceByIndex(0).getCanvasByIndex(0);

  if (version === "2") {
    const image = canvas.getImages()[0];
    const resource = image.getResource();
    const service = resource.getServices()[0];
    return {
      url: service.id,
      width: resource.getWidth(),
      height: resource.getHeight(),
    };
  }

  if (version === "3") {
    const annotation = canvas.getContent()[0];
    const body = annotation.getBody()[0];
    const service = body.getServices()[0];

    return {
      url: service.id,
      width: body.getWidth(),
      height: body.getHeight(),
    };
  }
}

// As we want native DOM API validation; ref is an appropriate use case
let formEl = null;
let infoJsonField = null;

/**
 * Returns array of inputs with errors given a form element
 *
 * @param {HTMLElement} formEl a <form> element
 * @returns {Array} List of invalid input HTMLElements
 */
const getInvalidInputs = (formEl) => {
  const formInputNodes = formEl.querySelectorAll('input');
  const formInputArray = Array.from(formInputNodes);

  // Filter inputs without errors
  const inputsWithErrors = formInputArray.filter(child => !child.checkValidity());
  return inputsWithErrors;
}

/**
 * Adds list of inputs with validation errors to `#error-container` to be read by screen readers
 *
 * @param {Array} invalidInputs A list of invalid input HTMLElements
 */
const alertErrors = (invalidInputs) => {
  // Create array of the labels of inputs with errors
  const inputsWithErrorsList = invalidInputs.map(input => {
    const inputParent = input.parentElement;
    let label;

    // 'Terms' label is more descriptive than we need for this purpose
    if (input.name === 'terms') {
      label = 'Terms and conditions';
    } else {
      label = inputParent.querySelector('#input-label');
      label = label.textContent.trim();
    }

    return label;
  });

  // Add list to error alert container
  const inputsListEl = document.querySelector('#error-container__inputs-list');
  inputsWithErrorsList.forEach(error => {
    const listItem = document.createElement('li');
    listItem.textContent = error;
    inputsListEl.appendChild(listItem);
  });

  // Unhide error message (to SRs-only) - hidden by default to stop it being read aloud on load
  const errorContainer = document.querySelector('#error-container');
  errorContainer.classList.remove('d-none');
}

function SubmitButton(props) {
  return (
    <button
      id="begin-button"
      onClick={props.validate}
      className={props.className}
      disabled={props.disabled}
    >
      {props.checkingInfoState && <Spinner/>}
      {props.text}
    </button>
  );
}

function Form(props) {
  const [checkingInfoState, setCheckingInfoState] = useState(false);
  const [formValidated, setFormValidated] = useState('');
  const [t] = useTranslation();

  const handleChange = (e) => {
    setFormValidated('');
    props.setInputValues((inputValues) => {
      const newValues = {...inputValues};
      newValues[e.target.name] = e.target.value;
      return newValues;
    });
    e.persist();
  };

  // Lock fields where data comes from URL
  const lockRights = () => {
    return props.inputValues.provider === 'bnf' ? true : false;
  };
  const lockInfoUrl = () => {
    return props.inputValues.provider === 'bnf' ? true : false;
  };

  // For BnF there is a default rights statement
  // In all cases, if a rights statement comes via the URL this trumps
  const setRights = () => {
    let rights = props.inputValues.attribution;
    if (
      !rights &&
      props.inputValues.provider === 'bnf'
    ) {
      rights = 'Source: gallica.bnf.fr';
    }
    return rights;
  }

  // Attempt to help the user by fixing values we know to be wrong
  // Sanitise the URL to leave us either a IIIF image ID or a manifest URL
  const sanitiseInfoJsonUrl = () => {
    let value = infoJsonField.value;
    // If it's a valid URL
    if (
        (!infoJsonField.validity.valueMissing) &&
        (!infoJsonField.validity.patternMismatch)
      ) {
      if (value[value.length - 1] === '/') {
        value = value.slice(0, -1);
      }
      if (value.slice(value.length - 10, ) === '/info.json') {
        value = value.slice(0, -10);
      }

      // If sanitisation was needed, update value
      if (infoJsonField.value !== value) {
        props.setInputValues((inputValues) => {
          const newValues = {...inputValues};
          newValues.infoUrl = value;
          return newValues;
        });
      }
      infoJsonField.setCustomValidity('');
    } else {
      infoJsonField.setCustomValidity(t('Form.enter valid iiif url'));
    }
    return true;
  }

  const getInfoJsonValidityMsg = () => {
    let msg = '';
    if (infoJsonField) {
      msg = infoJsonField.validationMessage;
    }
    return msg;
  };

  const renderSubmitButton = () => {
    let [text, disabled] = [t('Form.begin story'), false];

    if (checkingInfoState) {
      text = t('Form.verifying info json')
      disabled = true;
    }

    if (props.retrievingManifest) {
      disabled = true;
    }

    if (props.infoData.url) {
      text = t('Form.loading viewer')
    }

    return (
      <SubmitButton
        className="btn btn-primary d-flex align-items-center"
        text={text}
        validate={validate}
        disabled={disabled}
        checkingInfoState={checkingInfoState}
      />
    );
  };

  const renderEmailForm = () => {
    // check we are not on a client specific page
    // if we are on the default storiiies form then output the option
    // for file upload
    if(!props.inputValues.provider) {
      return (
        <div>
          <div className="form-group">
            <label htmlFor="email-field" id="input-label">{t('Form.email')}</label>
            <input
              value={props.inputValues.email}
              onChange={handleChange}
              name="email"
              type="text"
              className="form-control"
              id="email-field"
              required
            />
            <div className="invalid-feedback">
              {t('Form.required field')}
            </div>
          </div>
          <div className="email-form">
            <span><em>{t('Form.email terms')} </em> <br/><br/></span>
          </div>
        </div>
      )
    }
  }

  const renderTermsForm = () => {
    // check we are not on a client specific page
    // if we are on the default storiiies form then output the option
    // for file upload
    if(!props.inputValues.provider) {
      return (
        <div className="form-group flex-column terms-container mt-4">
          <input
            className="big-checkbox mr-2"
            id="terms"
            type="checkbox"
            required
            name="terms"
            aria-describedby="terms-error"
          />
          <label className="input-label" htmlFor="terms" id="input-label">
            I accept the terms and conditions (as below) *
          </label>
          <div className="invalid-feedback mt-2" id="terms-error">
            <span className="mr-1"><svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' stroke='#dc3545' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'></circle><path strokeLinejoin='round' d='M5.8 3.6h.4L6 6.5z'></path><circle cx='6' cy='8.2' r='.6' fill='#dc3545' stroke='none'></circle></svg></span>
            You must accept the terms and conditions
          </div>
        </div>
      )
    }
  }

  // Push form data to dataLayer for GTM
  const pushDataToDataLayer = () => {
    window.dataLayer.push({
      'event': 'formDataSubmitted',
      'storyTitle': props.inputValues.title,
      'author': props.inputValues.author,
      'storyDesc': props.inputValues.description,
      'attribution': props.inputValues.attribution
    });
  }

  const validate = (e) => {
    // Triggers from submission click has two parts:
    // Sanitise the info.json URL
    // Run form against validation API with checkValidity() (returns Boolean)
    // Check the response for the info.json

    // Reset file select validity
    const fileSelectButton = document.querySelector('#file-select-button');
    // If coming from email link there is no fileSelectButton
    if (fileSelectButton) {
      fileSelectButton.setCustomValidity('');
    }
    
    // Sanitise info json url (will update validity if error)
    // Check form validity (returns true if ok, false if errors)
    const checkValues = () => {
      return (
        sanitiseInfoJsonUrl() &&
        formEl.checkValidity()
      );
    }

    const checkManifestJson = (json) => {
      // for some reason this log is required to make the then fire in Chrome DO NOT REMOVE
      console.log("Validating manifest");
      const manifest = new Manifest(json);
      if (manifest.isManifest()) {
        // IS valid manifest.json

        // Get first image of manifest - this throws an error if unsuccessful
        const firstResource = getFirstResource(manifest);

        props.setInputValues((inputValues) => {
          const newValues = {...inputValues};
          newValues.infoUrl = firstResource.url;
          return newValues;
        });

        // Jury's out on whether width / height
        // is a MUST requirement in a manifest (we think it might not be)
        props.setInfoData({
          url: `${infoJsonField.value}/info.json`,
          width: firstResource.width,
          height: firstResource.height,
        });
      } else {
        // ISN'T valid info.json
        infoJsonField.setCustomValidity(t('Form.invalid json'));
        formEl.checkValidity();
        setCheckingInfoState(false);
      }
    }

    /**
     * Check whether the (sanitised) value from infoJsonField is a valid manifest.json URL
     * 
     * Fetch `infoJsonField.value` and validate response
     * If is valid: Check manifest JSON validity (`checkManifestJson()`)
     * If is not: Append `manifest.json` to value and fetch again
     * [with the modified URL]:
     * If this is valid: Check manifest JSON validity (`checkManifestJson()`)
     * If this is not: Throw error (can't use URL provided by user)
     *
     */
    const checkManifest = () => {
      fetch(`${infoJsonField.value}`)
        .then((response) => {
          // for some reason this log is required to make the then fire in Chrome DO NOT REMOVE
          console.log('Received info.json response');
          return response.json();
        })
        .then((json) => {
          checkManifestJson(json);
        })
        .catch((error) => {
          // Error during fetch;
          fetch(`${infoJsonField.value}/manifest.json`)
            .then((response) => {
              // for some reason this log is required to make the then fire in Chrome DO NOT REMOVE
              console.log('Received info.json response');
              return response.json();
            })
            .then((json) => {
              checkManifestJson(json);
            })
            .catch((error) => {
              infoJsonField.setCustomValidity(t('Form.request error'));
              formEl.checkValidity();
              setCheckingInfoState(false);
            })
        })
    }

    /**
     * Check whether the (sanitised) value from infoJsonField is a valid IIIF image ID 
     * 
     * Fetch `infoJsonField.value/info.json` and validate response
     * If is valid: Update props
     * If is not: Check whether the value is instead a manifest.json URL
     *
     */
    const checkInfoJsonResponse = () => {
      // Use the sanitised URL
      fetch(`${infoJsonField.value}/info.json`)
        .then((response) => {
          // for some reason this log is required to make the then fire in Chrome DO NOT REMOVE
          console.log('Received info.json response');
          return response.json();
        })
        .then((json) => {
          // for some reason this log is required to make the then fire in Chrome DO NOT REMOVE
          console.log('Validating info.json');

          if (
            // iiif image API 2.1 / 3
            (json.hasOwnProperty('protocol') &&
              json['protocol'].includes('iiif.io/api/image')) ||
            // iiif image API 1.1
            (json.hasOwnProperty('@context') &&
              json['@context'].includes('iiif/image-api')) )
          {
            // IS valid info.json
            props.setInfoData({
              url: `${infoJsonField.value}/info.json`,
              width: json.width,
              height: json.height,
            });
          }
          else {
            checkManifest();
          }
        })
        .catch((error) => {
        //Check manifest.json before failure
        checkManifest();
      })
    }

    if (checkValues()) {
      setCheckingInfoState(true);

      // Once validated, push to dataLayer
      pushDataToDataLayer();

      // Hack to get updated state
      setTimeout(() => {
        checkInfoJsonResponse();
      }, 2000);
    } else {
      const invalidInputs = getInvalidInputs(formEl);
      // Add errors to alert container
      alertErrors(invalidInputs);
      // Move focus to first invalid input
      invalidInputs[0].focus();
    }

    setFormValidated('was-validated');
    e.preventDefault();
  }

  return (
    <div>
      {/* display: none until there are errors as SR reads it out on page load */}
      <div id="error-container" className="form-error-container mb-4 visually-hidden d-none" role="alert">
        <p>There are errors on the following fields:</p>
        <ul id="error-container__inputs-list"></ul>
      </div>
      {/* check we are not on a client specific page
      if we are on the default storiiies form then output the option
      for file upload */}
      {!props.inputValues.provider 
        && <FileUploadForm 
          infoJsonField={infoJsonField} 
          inputValues={props.inputValues}
        />}
      <form
        ref={form => formEl = form} // Needed to interact with the native validation API
        className={`intro__form ${formValidated}`} // Bootstrap messages/styles display based on this class
        noValidate
      >
        <fieldset id="main-form">

          {/* Add notification if retrieving story from sid */}
          {props.retrievingManifest && 
            <div className="mb-4">
              <Spinner />
              <span>{t('Form.retrieving story')}</span>
            </div>
          }
          
          <div className="form-group">
            <label htmlFor="info-json-field" id="input-label">{t('Form.info json url')}</label>
            <input
              ref={field => infoJsonField = field}
              id="info-json-field"
              value={props.inputValues.infoUrl}
              onChange={handleChange}
              name="infoUrl"
              type="text"
              className="form-control"
              aria-describedby="info-json-url-error"
              required
              pattern="^https?:\/\/.+"
              disabled={lockInfoUrl() || props.retrievingManifest}
            />
            <div className="invalid-feedback" id="info-json-url-error">
              {getInfoJsonValidityMsg()}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="title-field" id="input-label">{t('Form.title')}</label>
            <input
              value={props.inputValues.title}
              onChange={handleChange}
              name="title"
              type="text"
              className="form-control"
              id="title-field"
              aria-describedby="title-error"
              required
              disabled={props.retrievingManifest}
            />
            <div className="invalid-feedback" id="title-error">
              {t('Form.required field')}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="author-field" id="input-label">{t('Form.author')}</label>
            <input
              value={props.inputValues.author}
              onChange={handleChange}
              name="author"
              type="text"
              className="form-control"
              id="author-field"
              disabled={props.retrievingManifest}
            />
          </div>
            {renderEmailForm()}
            <div className="form-group">
            <label htmlFor="description" id="input-label">{t('Form.description')}</label>
            <textarea
              value={props.inputValues.description}
              onChange={handleChange}
              name="description"
              id="description"
              className="form-control"
              disabled={props.retrievingManifest}
            />
          </div>
          <div className="form-group">
            <label htmlFor="attribution" id="input-label">{t('Form.image rights')}</label>
            <textarea
              value={setRights()}
              onChange={handleChange}
              name="attribution"
              id="attribution"
              className="form-control"
              disabled={lockRights() || props.retrievingManifest}
            >
            </textarea>
          </div>
          {renderTermsForm()}
        </fieldset>
        <div className="form-group form-row justify-content-end">
          {renderSubmitButton()}
        </div>
      </form>
    </div>
  );
}

export default Form;
