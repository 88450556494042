import React, { useState } from 'react';
import axios from 'axios';
import Spinner from './components/Spinner';

let state = {
  file: null
};

let link = {
  url: null,
  label: 'Storiiies upload'
}

export default function FileUploadForm(props) {
  const errorContainer = document.querySelector('#file-select-validity');
  const fileSelectButton = document.querySelector('#file-select-button');
  const successMsgEl = document.querySelector('.image-upload-form__success_msg');
  const uploadButton = document.querySelector('#upload-button');
  const beginButton = document.querySelector('#begin-button');
  const mainFormFieldSet = document.querySelector('#main-form');
  const statusMessageContainer = document.querySelector('#status-message-container');
  const [imageIsProcessing, setImageIsProcessing] = useState(false);
  const [formValidated, setFormValidated] = useState('');

  const handleFileSubmit = (event) => {
    event.preventDefault();

    // Check we have a file selected, show error message if not
    if (!state.file || !state.file[0]) {
      // Show an error message to prompt to select image
      fileSelectButton.setCustomValidity('Please select a valid file (jpeg, jpg).');
      errorContainer.classList.add('is-invalid');
      errorContainer.innerHTML = fileSelectButton.validationMessage;
    } else {
      setImageIsProcessing(true);
  
      // Disable form while image uploads and is converted
      uploadButton.disabled = true;
      uploadButton.innerHTML = 'Uploading...';
      statusMessageContainer.innerHTML = 'Image processing. Please wait then fill in the rest of the form when it becomes active. This can take up to one minute.';
      fileSelectButton.disabled = true;
  
      mainFormFieldSet.disabled = true;
      beginButton.disabled = true;
  
      // NB: Not the correct way to use props, but will leave for now
      props.infoJsonField.disabled = true;
      props.infoJsonField.value = 'Waiting for upload...';
      props.inputValues.infoUrl = 'Waiting for upload...';
  
      /* Sanitise file name before sending to server */
      // Get file
      const imageFile = state.file[0];
      const imageFileName = imageFile.name;
      // Split by . to split file extension and file name
      const fileNameComponents = imageFileName.split('.');
      const fileExtension = fileNameComponents.pop();
      // (File name minus extension)
      let fileName = fileNameComponents.join();
      // Remove anything other than a-z A-Z 0-9 from filename (inc. diacritics, etc)
      fileName = fileName.replace(/[^A-Za-z0-9]/g, '');
      // Handle files which end up with an empty file name after above (e.g.: all non-latin character file names)
      if (fileName.length === 0) {
        // Add a random 6-digit number to file name
        const randomNo = Math.floor(100000 + Math.random() * 900000);
        fileName = `${fileName}${randomNo}`;
      }
      // Create new file object w/ sanitised name
      const sanitisedImageFile = new File([state.file[0]], `${fileName}.${fileExtension}`);
  
      /* Send data to image server */
      const formData = new FormData();
      formData.append('file', sanitisedImageFile);
      axios.post('https://hs-image-in.herokuapp.com/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        /* Get file name from response 
        NB: different from filename we sent to server above as has timestamp added 
        to the beginning */
        const responseImageFileName = response.data.Key;
        // Get file name minus extension
        let resFileNameComponents = responseImageFileName.split('.');
        // Remove extension
        resFileNameComponents.pop();
        const resFileName = resFileNameComponents.join();
  
        // Create Story image URL and add to fields in form
        link.url = `https://storiiies-images.cogapp.com/iiif/2/${resFileName}.ptif`;
        props.infoJsonField.value = link.url;
        props.inputValues.infoUrl = link.url;
  
        uploadButton.innerHTML = 'Converting image...';
  
        // Poll info.json URL to see if conversion is done
        checkForConversion(props.infoJsonField.value)
          .then(response => {
            setImageIsProcessing(false);
            // Show success msg
            successMsgEl.classList.add('is-valid');
            statusMessageContainer.innerHTML = 'Image processed successfully. Please fill in the rest of the form.';
            // Re-enable form again
            reenableForm();
        }).catch(error => {
          // Reset
          reenableForm(true);
          setImageIsProcessing(false);
          successMsgEl.classList.remove('is-valid');
          statusMessageContainer.innerHTML = '';
          // Show error msg
          // NB: setting custom validity has to be done _after_ fileSelectButton is reenabled, otherwise it won't work
          fileSelectButton.setCustomValidity(`${error}. Please try again or try a different file.`);
          errorContainer.classList.add('is-invalid');
          errorContainer.innerHTML = fileSelectButton.validationMessage;
        });
      }).catch(error => {
        // Reset
        reenableForm(true);
        setImageIsProcessing(false);
        successMsgEl.classList.remove('is-valid');
        statusMessageContainer.innerHTML = '';
        // Show error msg
          // NB: setting custom validity has to be done _after_ fileSelectButton is reenabled, otherwise it won't work
        fileSelectButton.setCustomValidity(`${error}. Please try again or try a different file.`);
        errorContainer.classList.add('is-invalid');
        errorContainer.innerHTML = fileSelectButton.validationMessage;
      });
    }
    setFormValidated('was-validated');
  }
  
  const handleChange = (event) => {
    // Reset when new file selected
    resetValidation();
    reenableForm();
    state.file = event.target.files;
    setImageIsProcessing(false);
    props.infoJsonField.disabled = false;
    successMsgEl.classList.remove('is-valid');
    statusMessageContainer.innerHTML = '';
  
    // Check file is below 30MB
    const file = event.target.files[0];
    if (file) {
      const fileSize = file.size;
      const fileMb = fileSize / 1024 ** 2;
      
      if (fileMb > 30) {
        uploadButton.disabled = true;
        fileSelectButton.setCustomValidity('File selected is too large. Please select another file.');
        errorContainer.innerHTML = fileSelectButton.validationMessage;
        // Show error/reset form
        errorContainer.classList.add('is-invalid');
      }
    }
    setFormValidated('was-validated');
  }
  
  /**
   * Polls for existence of info.json on server
   *
   * @param {string} infoJsonUrl URL to poll
   * @param {number} tryLength The length of time the server should be polled before returning a time out error (in seconds)
   * @returns {Promise} 
   * @throws {Error} Times out if tryLength runs out without a successful response from server
   */
  const checkForConversion = (infoJsonUrl, tryLength = 60) => {
    /* Send HEAD req to infoJsonUrl. Return true if success, otherwise false */
    const callback = async (infoJsonUrl) => {
      try {
        const response = await fetch(`${infoJsonUrl}/info.json`, {
          method: 'HEAD',
        });
        if (response.ok) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
  
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        if (await callback(infoJsonUrl)) {
          resolve();
          clearInterval(interval);
        } else if (tryLength <= 1) {
          reject(new Error('Image conversion failed'));
          clearInterval(interval);
        }
        tryLength--;
      }, 1000);
    });
  };

  /**
   * Resets any validation/error messages
   *
   */
  const resetValidation = () => {
    setFormValidated('');
    fileSelectButton.setCustomValidity('');
    errorContainer.classList.remove('is-invalid');
    errorContainer.innerHTML = '';
    successMsgEl.classList.remove('is-valid');
  }

  /**
   * Reenables/resets form elements
   * * @param {boolean} [resetInfoJsonField = false] Whether to include the info JSON URL field
   *
   */
  const reenableForm = (resetInfoJsonField = false) => {
    beginButton.disabled = false;
    mainFormFieldSet.disabled = false;
    uploadButton.disabled = false;
    uploadButton.innerHTML = 'Upload';
    fileSelectButton.disabled = false;

    if (resetInfoJsonField) {
      props.infoJsonField.disabled = false;
      props.infoJsonField.value = '';
      props.inputValues.infoUrl = '';
    }
  }

  return (
    <form className={`image-upload-form ${formValidated}`} onSubmit={handleFileSubmit}>
      <p className="mb-2">Upload your JPEG image here (or specify a IIIF image or manifest in the space below.)</p>
      <b>Maximum file size: 30MB</b>
      <div className="form-group justify-content-start">
        <label htmlFor="file-select-button" id="input-label" className="mt-4">Select a file</label>
        <input id="file-select-button" className="form-control" label='upload file' type='file' accept=".jpg,.jpeg" onChange={handleChange} aria-describedby="file-select-validity"/>
        <div className="invalid-feedback" id="file-select-validity" role="alert"></div>
      </div>
      <div className="form-group form-row justify-content-start m-0">              
        {imageIsProcessing && <Spinner/>}
        {/* Reset valdiation on click so user can try submitting same file again */}
        <button id='upload-button' className="btn btn-primary d-flex align-items-center" type='submit' onClick={resetValidation}>Upload</button>
      </div>
      {/* Show/read status inc. success messages */}
      <div id="status-message-container" className="image-upload-form__success_msg mt-2" aria-live="polite"></div>
    </form>
  )
}
