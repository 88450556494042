import './css/viewer.css';
import React, { useEffect } from 'react';
import OpenSeadragon from 'openseadragon';

function Osd(props) {

  const initOpenSeadDragon = () => {
    OpenSeadragon({
      id: 'osd',
      zoomInButton:'zoom-in-button',
      zoomOutButton: 'zoom-out-button',
      timeout: '60000',
      tileSources: [
        props.infoData.url,
      ],
    }).addOnceHandler('open', function(source) {
      props.setOsdInstance(source.eventSource);
    });
  }
  
  useEffect(() => {
    initOpenSeadDragon();
    props.sendManifest();
  }, []);


  return (
    <div>
      <div className="osd-viewer" id="osd">
      </div>
    </div>
  );
}

export default Osd;