import React from 'react';
import Links from './components/Links';
import { useTranslation } from 'react-i18next';

function StoriiiInfo(props) {
  const previewUrl = props.viewURL;
  const [t] = useTranslation();

  const handleSubmit = e => {
    window.open(previewUrl, '_blank');
    e.preventDefault();
  };

  return (
    <div className='storiii-info form-inline'>
      <div className='form-group form-row'>
        <Links 
          viewURL={props.viewURL}
          storyID={props.storyID}
          title={props.title}
          language={props.language}
        />
        <button onClick={handleSubmit} className='btn btn-primary ml-2'>
          {t('StoriiiInfo.preview')}
        </button>
      </div>
    </div>
  );
}

export default StoriiiInfo;
