import React, { useState, useEffect, Suspense } from 'react';
// import { CSSTransition } from 'react-transition-group';
import Intro from './Intro';
import Osd from './Osd';
import ViewerControls from './ViewerControls';
import StoriiiInfo from './StoriiiInfo';
import Annotations from './Annotations';
import { ReactComponent as Sprite } from './images/sprite.svg';
import axios from 'axios';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import DangerousTranslation from './DangerousTranslation';


function App() {
  const [t] = useTranslation();
  const [infoData, setInfoData] = useState({
    url: null,
    width: null,
    height: null
  });
  // Some updates to this state Hook may seem more convoluted
  // because of it being an Object
  const [inputValues, setInputValues] = useState({
    email: '',
    title: '',
    author: '',
    description: '',
    attribution: '',
    infoUrl: '',
    storyID: '',
    viewURL: '',
    provider: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [retrievingManifest, setRetrievingManifest] = useState(false);
  const subdomain = process.env.REACT_APP_API_SUBDOMAIN;
  const queryParams = queryString.parse(window.location.search);
  const [osdInstance, setOsdInstance] = useState(null);
  const [showAnnotations, setShowAnnotations] = useState(true);
  const manifestEndpoint =
    'https://' + subdomain + '.execute-api.us-east-1.amazonaws.com/dev/storiiiy';
  const annotationEndpoint =
    'https://' + subdomain + '.execute-api.us-east-1.amazonaws.com/dev/newannotation';
  const emailEndpoint =
      'https://' + subdomain + '.execute-api.us-east-1.amazonaws.com/dev/sendemail?';
  const subscribeEndpoint =
      'https://' + subdomain + '.execute-api.us-east-1.amazonaws.com/dev/subscribe?';
  const viewerClassName = {
    viewer: true,
    'viewer--annotations': showAnnotations
  };
  const providerLogos = {
    'bnf': {
      src: '/provider_logos/bnf.png',
      href: 'https://gallica.bnf.fr/',
      alt: 'Bibliothèque nationale de France'
    },
  }
  const language = queryString.parse(window.location.search).lang;
  const renderIntro = () => {
    if (!osdInstance) {
      return (
        <Intro
          key='intro'
          setInfoData={setInfoData}
          infoData={infoData}
          inputValues={inputValues}
          setInputValues={setInputValues}
          providerLogos={providerLogos}
          retrievingManifest={retrievingManifest}
          errorMessage={errorMessage}
        />
      );
    }
  };

  useEffect(() => {
    document.title = t('app title');
    // To differentiate data that came via the URL
    // Update inputs if they came in via url
    // (all properties must be set to avoid warnings otherwise they would become undefined)
    // Trim info.json for niceness
    let queryResource = queryParams.resource;
    if (queryResource) {
      if (queryResource.slice(queryResource.length - 10, ) === '/info.json') {
        queryResource = queryResource.slice(0, -10);
      }
    }
    setInputValues({
      ...inputValues,
      storyID: queryParams.sid || inputValues.sid,
      provider: queryParams.provider || inputValues.provider,
      infoUrl: queryResource || inputValues.infoUrl,
      attribution: queryParams.rights || inputValues.attribution,
    });
    // Load existing story
    if (queryParams.sid) {
      // Hide the file upload form
      // (Currently it is hidden if inputValues.provider has a value, so for ease we just set a temporary value here)
      setInputValues({
        ...inputValues,
        provider: 'temp',
      });

      retrieveManifest(queryParams.sid);
    }
  }, []);

  const [storedAnnotations, setStoredAnnotations] = useState({});

  const retrieveManifest = storyID => {
    setRetrievingManifest(true);
    axios(`${manifestEndpoint}/${storyID}`).then(res => {
      setRetrievingManifest(false);
      setInputValues({
        title: res.data.title,
        author: res.data.author,
        description: res.data.description,
        attribution: res.data.rights,
        infoUrl: res.data.iiifImageID,
        storyID: storyID,
        viewURL: res.data.viewURL,
        provider: res.data.provider,
      });
      setStoredAnnotations(res.data.annotations);

      // Set document title
      document.title = `${res.data.title} - Storiiies Editor`;
    }).catch(error => {
      setRetrievingManifest(false);
      // Render the file upload form again
      setInputValues({
        ...inputValues,
        provider: '',
      });
      // Set error message to be shown on frontend
      setErrorMessage("Error: Could not retrieve story. Please try again or create a new story below.");
    });
  };

  const sendManifest = () => {
    axios
      .post(
        manifestEndpoint,
        JSON.stringify({
          author: inputValues.author || 'Unknown',
          title: inputValues.title,
          description: inputValues.description || ' ',
          imageURL: infoData.url.split('/info.json')[0],
          imageTitle:
            inputValues.title +
            '\n' +
            inputValues.author +
            '\n\n' +
            inputValues.description +
            '\n\n' +
            inputValues.attribution,
          imageAttribution: inputValues.attribution || ' ',
          imageWidth: infoData.width,
          imageHeight: infoData.height,
          storyID: inputValues.storyID || 'empty',
          provider: queryParams.provider || 'cogapp',
        })
      )
      .then(res => {
        const { viewURL, storyID } = res.data;
        setInputValues({
          ...inputValues,
          viewURL,
          storyID
        });

        //Send email
        if (inputValues.email) {
          const emailQueryString = "email=" + inputValues.email + "&title=" + inputValues.title + "&author=" +
              inputValues.author + "&viewurl=" + viewURL + "&editor=" + storyID;
          const subscribeQueryString = "email=" + inputValues.email + "&author=" + inputValues.author;
          axios.post(`${emailEndpoint}${emailQueryString}`)
              .then(function(res) {
            console.log("Sent email successfully");
          })
              .catch(function (error) {
                console.log(error);
              });
          axios.post(`${subscribeEndpoint}${subscribeQueryString}`)
              .then(function(res) {
                console.log("Signed up successfully");
              })
              .catch(function (error) {
                console.log(error);
              });
        }
      });
  };

  const renderAnnotations = () => {
    return (
      <Annotations
        osdInstance={osdInstance}
        annotationEndpoint={annotationEndpoint}
        showAnnotations={showAnnotations}
        setShowAnnotations={setShowAnnotations}
        viewerClassName={viewerClassName}
        infoData={infoData}
        storyID={inputValues.storyID}
        storedAnnotations={storedAnnotations}
      />
    );
  };

  const renderViewer = () => {
    const className = {
      base: 'viewer',
      annotations: ''
    };

    className.annotations = showAnnotations
      ? `${className.base}--annotations-open`
      : '';

    if (infoData.url) {
      // Set document title
      document.title = `${inputValues.title} - Storiiies Editor`;

      return (
        <div
          id='viewer'
          className={`${className.base} ${className.annotations}`}
        >
          {renderAnnotations()}
          <div className='viewer-wrapper'>
            <Osd
              setOsdInstance={setOsdInstance}
              infoData={infoData}
              sendManifest={sendManifest}
            />

            <ViewerControls />
            <StoriiiInfo
              viewURL={inputValues.viewURL}
              storyID={inputValues.storyID}
              title={inputValues.title}
              language={language}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div id='editor'>
      <Suspense fallback={"loading"}>
        <CookieConsent
          debug={true}
          disableStyles={true}
          buttonClasses="btn btn-primary cookieConsent__button"
          containerClasses="alert alert-light text-dark"
          contentClasses="cookieConsent__text"
          buttonText={t('Intro.cookieButton')}
        >
          <DangerousTranslation i18nKey="Intro.cookieText" />
        </CookieConsent>
        <Sprite />
        {renderIntro()}
        {renderViewer()}
      </Suspense>
    </div>
  );
}

export default App;
