import React from 'react';

export default function Spinner(props) {
  return (
    <span
      className='spinner-border spinner-border-sm mx-2 my-auto'
      role='status'
      aria-hidden='true'
    ></span>
  );
}
