import './css/annotations.css';
import React, { useState, useEffect } from 'react';
import AnnotationList from './AnnotationList';
import AnnotationForm from './AnnotationForm';
import { DragDropContext } from 'react-beautiful-dnd';
import { Trans, useTranslation } from 'react-i18next';

function Annotations(props) {
  const [showAddAnnotation, setShowAddAnnotation] = useState(false);
  const [annotations, setAnnotations] = useState([]);
  const [t] = useTranslation();

  useEffect(() => {
    let newAnnotations = [];
    if (
      Object.entries(props.storedAnnotations).length !== 0 &&
      props.storedAnnotations.constructor !== Object
    ) {
      props.storedAnnotations.forEach(annotation => {
        let newAnnotation = {
          storyID: props.storyID,
          imageURL: annotation.imageURL,
          text: annotation.text,
          coords: { ...annotation.coords },
          ordinal: annotation.ordinal,
          id: annotation.id
        };
        newAnnotations.push(newAnnotation);
      });

      setAnnotations(newAnnotations);
    }
  }, []);

  const toggleAnnotations = e => {
    // Fuzzy click anywhere when closes, only button when open
    if (
      props.showAnnotations &&
      (!e.target.classList.contains('toggle-annotations') &&
        !e.target.closest('.toggle-annotations'))
    )
      return;

    props.setShowAnnotations(!props.showAnnotations);
    e.stopPropagation();
  };

  const toggleAddAnnotation = () => {
    setShowAddAnnotation(!showAddAnnotation);
  };

  const renderAddForm = () => {
    if (showAddAnnotation) {
      return (
        <AnnotationForm
          invocation='add'
          osdInstance={props.osdInstance}
          annotationEndpoint={props.annotationEndpoint}
          infoData={props.infoData}
          storyID={props.storyID}
          ordinal={props.ordinal}
          setOrdinal={props.setOrdinal}
          setShowAddAnnotation={setShowAddAnnotation}
          annotations={annotations}
          setAnnotations={setAnnotations}
        />
      );
    }
  };

  const handleDragEnd = result => {
    const { destination, source } = result;
    // A dragEnd may not result in a change
    // might be a drop outside the droppable
    // or might end prematurely (e.g. esc)
    if (destination && destination.index !== source.index) {
      let newAnnotations = annotations.slice(0);
      let oldAnnoations = annotations.slice(0, 0);
      const movedAnnotation = newAnnotations.splice(source.index, 1)[0];
      newAnnotations.splice(destination.index, 0, movedAnnotation);

      // Reindex the ordinal
      // This is bound to each draggable, but will not update itself
      newAnnotations.forEach((item, index) => {
        item.ordinal = index;
      });
      // TODO: it would be good if the fetch and revert only sent ordinals
      fetch(props.annotationEndpoint, {
        method: 'POST',
        body: JSON.stringify(newAnnotations),
        headers: {
          'Content-Type': 'application/json'
        }
      }).catch(err => {
        console.log(err);
        // TODO: Show error
        // For now this is the simplest way of reverting if there's an error
        setAnnotations(oldAnnoations);
      });

      // To make this less clunky assume success
      setAnnotations(newAnnotations);
    }
  };

  return (
    <div className='annotations-wrapper' onClick={toggleAnnotations}>
      <button
        className='btn btn-primary btn--icon add-new-annotation'
        onClick={toggleAddAnnotation}
      >
        <span className='btn__text'>{t('Annotations.add new')}</span>
        <svg className='btn__icon'>
          <use xlinkHref='#add'></use>
        </svg>
      </button>
      <Trans i18nKey="Annotations.directions">
        <small>
          Add annotations to a region,{' '}
          <span className='nowrap'>drag-and-drop</span> to re-order.
        </small>
      </Trans>
      {renderAddForm()}
      <DragDropContext onDragEnd={result => handleDragEnd(result)}>
        <AnnotationList
          osdInstance={props.osdInstance}
          annotationEndpoint={props.annotationEndpoint}
          infoData={props.infoData}
          manifestId={props.manifestId}
          setOrdinal={props.setOrdinal}
          annotations={annotations}
          setAnnotations={setAnnotations}
        />
      </DragDropContext>
      <button className='btn toggle-annotations' onClick={toggleAnnotations}>
        <span className='hide-annotations__text'>{t('Annotations.hide annotations')}</span>
        <svg className='toggle-annotations__icon hide-annotations__icon'>
          <use xlinkHref='#hide'></use>
        </svg>
        <svg className='toggle-annotations__icon show-annotations__icon'>
          <use xlinkHref='#show'></use>
        </svg>
      </button>
    </div>
  );
}

export default Annotations;
